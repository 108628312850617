import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({ children, isContact = false }) => {
  const title = "SecSafeConsult";
  const description = "Øg sikkerheden på arbejdspladsen med et informativt kursus.";
  const keywords = "SecSafeConsult, Konsulent, Konsultering, Sikkerhed, Safety, Arbejdssikkerhed, Luftfart, Aarhus Airport";

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="keywords"
          content={keywords}
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/hero.jpg`}
        />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer isContact={isContact} />
    </>
  )
}

export default TemplateWrapper
