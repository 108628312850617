import React from 'react'
import logo from '../img/logo.svg'
import { Nav, NavItem, Row, Col, Icon, Image, LinkButton, Text, Container, Heading, RouterButton } from './UI'

const Footer = ({ isContact }) => {
  return (
    <>
      {/* Check to see if we are on contact page, if so dont render ContactOverview */}
      {isContact ? <>
        {/* ContactOverview */}
        <Container backgroundColor="button.1" py="xxxxl">
          <Container maxWidth="content" textAlign="center" mx="auto">
            <Heading type="h2" highlight="center" color="bg.0" mb="xl">Skriv direkte på email</Heading>
            <LinkButton variant="border" p="md" color="bg.1" href="mailto:secsafeconsult@gmail.com">secsafeconsult@gmail.com</LinkButton>
          </Container>
        </Container>
      </> : <>
        {/* ContactOverview */}
        <Container backgroundColor="button.1" py="xxxxl">
          <Container maxWidth="content" textAlign="center" mx="auto">
            <Heading type="h2" highlight="center" color="bg.0" mb="xl">Kontakt mig</Heading>
            <RouterButton to="/contact" m="0" p="0" px="lg" py="md" variant="border">Skriv mig en mail</RouterButton>
          </Container>
        </Container>
      </>}

      <Nav p="0" px={["0", "xxxl"]} py="xxxxl">
        <Row style={{ width: '100%' }}>
          <Col md={3} textAlign="center">
            <NavItem to="/" my="md"><Image src={logo} alt="" width="24px" verticalAlign="middle"/> SecSafeConsult</NavItem>
            <Text ml="xxl" mb="0">CVR: 41234318</Text>
            <Text ml="xl">8530 Hjortshøj</Text>
          </Col>
          <Col md={3} textAlign="center">
            <NavItem my="md" to="/services">Services</NavItem>
            <NavItem my="md" to="/blog">Blog</NavItem>
          </Col>
          <Col md={3} textAlign="center">
            <NavItem my="md" to="/about">Om mig</NavItem>
            <NavItem my="md" to="/contact">Kontakt</NavItem>
          </Col>
          <Col md={3} textAlign="center">
            <LinkButton href="https://www.facebook.com/Secsafeconsult"><Icon icon={['fab', 'facebook-square']} size="2x" aria-label="Facebook ikon, der linker til firmaets facebook side" /></LinkButton>
          </Col>
        </Row>
      </Nav>
      <Nav backgroundColor="bg.1" p="xl">
        <Container maxWidth="1200px" mx="auto">
          <Text mb="0">Copyright © {(new Date().getFullYear())} SecSafeConsult - Alle rettigheder forbeholdes.</Text>
        </Container>
      </Nav>
    </>
  );
}

export default Footer
