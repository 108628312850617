import React, { useState } from 'react'
import logo from '../img/logo.svg'
import { Nav, NavItem, Image, Burger } from './UI'

const Navbar = () => {
  const [hidden, setHidden] = useState(true);

  return (
    <Nav maxWidth="content" mx="auto" p="0" py="xxl" px="xl">
      <Nav p="0" mr="auto">
        <NavItem to="/"><Image src={logo} alt="" width="24px" verticalAlign="middle"/> SecSafeConsult</NavItem>
        <Burger hidden={hidden} setHidden={setHidden} />
      </Nav>

      <Nav p="0" collapse hidden={hidden} justifyContent={['center', 'flex-end']} alignItems="center" width={["100%", "auto"]} pb={["48px", "0"]}>
        <NavItem mx="xl" to="/services" fontSize={['xl', 'md']}>Services</NavItem>
        <NavItem mx="xl" to="/blog" fontSize={['xl', 'md']}>Blog</NavItem>
        <NavItem mx="xl" to="/about" fontSize={['xl', 'md']}>Om mig</NavItem>
        <NavItem mx="xl" to="/contact" fontSize={['xl', 'md']}>Kontakt</NavItem>
      </Nav>
    </Nav>
  );
}

export default Navbar
